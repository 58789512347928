import React, { useState } from 'react'
import Layout from 'layouts/default'

import Hero from 'components/pages/ce200/hero'
import IntegrationSection from 'components/pages/cards/integration'
import richSnippet from 'components/common/rich-snippet'
import { createCardSchema } from 'utils/richSnippets'
import Comparison from 'components/pages/rewards/comparison'
import QualifySection from 'components/qualify'
import Modal from 'components/common/modal'
import Waitlist from 'components/common/modal/content/waitlist'
import CompanyType from 'components/common/enums/company-type'
import ImageCircle from 'assets/images/ce200/qualify_circle.png'
import LocalHeader from 'components/pages/ce200/local-header'
import styled from '@emotion/styled'
import ForMerchants from 'components/pages/ce200/for-merchants'

const QualifyContainer = styled.div`
  background: #E4F1EF;
  align-self: stretch;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  
  margin-top: 100px;
  
  padding: 50px 0;
  
  @media screen and (max-width: 768px) {
    padding: 0;
    margin-top: 30px;
  }
`

const ConstructionWelcomePage: React.FC = props => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  React.useEffect(() => {
    window.analytics.page('Landing Page', 'CE200')
  }, [])
  return (
    <Layout
      title='High credit line card for construction companies | ComCard'
      description='Receive no personal liability card with high credit limits that will help you save time and money.'
      helmetChildren={richSnippet(createCardSchema())}
      hideHeader
      disableFixedHeader
    >
      <Modal isOpen={isModalOpen} title="Join waitlist" onClose={() => setIsModalOpen(false)}>
        <Waitlist closeModal={() => setIsModalOpen(false)} defaultIndustry={CompanyType.CONSTRUCTION} />
      </Modal>
      <LocalHeader />
      <Hero setIsModalOpen={setIsModalOpen} />

      <Comparison />
      <IntegrationSection bottomSpacing={0} />
      <QualifyContainer>
        <QualifySection
          image={ImageCircle}
          headerText="Sign up to get early access"
          defaultIndustry={CompanyType.CONSTRUCTION}
          locationForEvent="CE200 Landing Hero"
        />
      </QualifyContainer>
      <ForMerchants />
    </Layout>
  )
}

export default ConstructionWelcomePage
