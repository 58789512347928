import * as React from 'react'
import ReceivesSection from 'components/pages/rewards/receives'
import styled from '@emotion/styled'
import Button, { ButtonSize } from 'components/common/button'
import HeroArt from 'assets/images/ce200/hero_art.svg'
import HeroArtMobile from 'assets/images/ce200/hero_art_mobile.svg'
import { Container } from 'components/common/styles'
import { between } from 'polished'
import ShieldSVG from 'assets/images/icons/shield.svg'
import CoinsSVG from 'assets/images/icons/coins.svg'
import MoneyOutSVG from 'assets/images/icons/money_out.svg'
import CardSVG from 'assets/images/icons/card.svg'

const GlobalContainer = styled.div`
  align-self: stretch;
  background: linear-gradient(180deg, rgba(228, 241, 239, 0) 0%, rgba(228, 241, 239, 0.645833) 68.26%, #E4F1EF 100%);
  
  display: flex;
  flex-direction: column;
  align-items: center;
`

const WidthContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.h1`
  font-size: 48px;
  line-height: 1.35;
  color: #000000;
  font-weight: bold;
  width: 70%;
  
  margin-top: 80px;
  margin-bottom: 40px;
  
  text-align: center;
  
  @media screen and (max-width: 1500px) {
    margin-top: 50px;
    font-size: ${between('34px', '48px', '768px', '1500px')};
    width: 90%;
  }
  
   @media screen and (max-width: 768px) {
    margin-top: 50px;
    font-size: 34px;
  }
`
const TitleHighlight = styled.span`
  color: ${props => props.theme.colors.green100};
`

const DesktopArt = styled.img`
  width: 100%;
  margin-bottom: 70px;
  
  @media screen and (max-width: 768px) {
    display: none;
  }
`

const MobileArt = styled.img`
  width: 100%;
  margin-bottom: 70px;
  
  @media screen and (min-width: 769px) {
    display: none;
  }
`

const MobileFeatures = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 32px;
  
  margin-top: 60px;
  margin-bottom: 30px;
  
  @media screen and (min-width: 769px) {
    display: none;
  }
`
const MobileFeature = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Icon = styled.img`
  margin-bottom: 16px;
  width: 24px;
  height: 24px;
`
const FeatureText = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
  text-align: center;
`

type Props = {
  setIsModalOpen: (boolean) => void
};

const Hero: React.FC<Props> = ({ setIsModalOpen }) => {
  return (
    <GlobalContainer>
      <WidthContainer>
        <Title>Finally, a <TitleHighlight>corporate card</TitleHighlight> tailored for construction businesses of any
          size.</Title>
        <Button size={ButtonSize.MEDIUM} onClick={() => {
          setIsModalOpen(true)
          window.analytics.track('Open the waitlist modal', {
            location: 'CE200 Landing Hero',
          })
        }}>Join Waitlist</Button>

        <MobileFeatures>
          <MobileFeature>
            <Icon src={ShieldSVG}/>
            <FeatureText>No Personal Guarantee</FeatureText>
          </MobileFeature>
          <MobileFeature>
            <Icon src={CoinsSVG}/>
            <FeatureText>Up To 30% Cashback</FeatureText>
          </MobileFeature>
          <MobileFeature>
            <Icon src={MoneyOutSVG}/>
            <FeatureText>Expense Management</FeatureText>
          </MobileFeature>
          <MobileFeature>
            <Icon src={CardSVG}/>
            <FeatureText>Employee Cards</FeatureText>
          </MobileFeature>
        </MobileFeatures>

        <DesktopArt src={HeroArt} />
      </WidthContainer>
      <MobileArt src={HeroArtMobile} />
      <ReceivesSection colored={false} />
    </GlobalContainer>
  )
}

export default Hero
