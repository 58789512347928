import * as React from 'react'
import styled from '@emotion/styled'
import ComCardLogo from 'assets/images/logo_green.svg'
import { LogoLink } from 'components/header/styles'
import CeLogoImage from 'assets/images/ce200/ce_logo.svg'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 18px 72px;
  font-size: 16px;
  width: 100%;
  max-width: 1680px;
  
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    padding: 18px 18px;
  }
`

const LocalLogoLink = styled(LogoLink)`
  @media screen and (max-width: 1024px) {
    align-self: flex-start;
  }
`

const CE200 = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  
  @media screen and (max-width: 1024px) {
    margin-top: 16px;
  }
`
const CeTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #1d1e21;
  margin-right: 24px;
  
  @media screen and (max-width: 1024px) {
    font-size: 14px;
  }
`
const CeLogo = styled.img`
  width: 54px;
  height: 48px;
`

type Props = {};


const LocalHeader: React.FC<Props> = props => {
  return (
    <Container>
      <LocalLogoLink to="/">
        <img src={ComCardLogo} alt="ComCard logo" />
      </LocalLogoLink>
      <CE200 href="https://www.constructionexec.com/" target="_blank">
        <CeTitle>Congratulations to our friends at Construction Executive on 200th edition!</CeTitle>
        <CeLogo src={CeLogoImage} />
      </CE200>
    </Container>
  )
}

export default LocalHeader
