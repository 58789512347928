import * as React from 'react'
import styled from '@emotion/styled'
import { Container } from 'components/common/styles'
import ArtImage from 'assets/images/ce200/for_merchants_art.svg'
import ReadMoreLink from 'components/common/read-more-link'

const WidthContainer = styled(Container)`
  position: relative;
`

const Art = styled.img`
  width: 70%;
  margin-top: 215px;
  margin-bottom: 100px;
  
  @media screen and (max-width: 1200px) {
    margin-top: 250px;
  }
  
  @media screen and (max-width: 850px) {
    width: 100%;
    margin-top: 60px;
    margin-bottom: 25px;
  }
`

const InfoContainer = styled.div`
  position: absolute;
  left: 55%;
  top: 120px;
  
  @media screen and (max-width: 1200px) {
    left: 50%;
  }
  
  @media screen and (max-width: 850px) {
    position: static;
    left: auto;
    top: auto;
    
    margin-bottom: 60px;
  }
`
const Title = styled.h3`
  font-weight: bold;
  font-size: 34px;
`
const Description = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: #666668;
  margin: 25px 0;
  max-width: 500px;
  
  padding-right: 20px;
  
  @media screen and (max-width: 850px) {
    min-width: 100%;
  }
`

type Props = {};
const ForMerchants: React.FC<Props> = props => {
  return (
    <WidthContainer>
      <Art src={ArtImage} />
      <InfoContainer>
        <Title>For merchants</Title>
        <Description>
          Join our growing merchant network to attract and retain customers with value-based loyalty programs, while
          reaping the benefits and savings of ComCard’s industry leading rewards card.
        </Description>
        <ReadMoreLink to="/merchants">Read More</ReadMoreLink>
      </InfoContainer>
    </WidthContainer>
  )
}

export default ForMerchants
